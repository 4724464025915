import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from '../../../theme';
import { toTime, isAfterMin } from '../../../utils/date';
import { getOption } from '../../../utils';
import { getURLSearchParamByKey } from '../../../utils';
import { t } from 'src/i18n/config';

const CardContainer = styled.div`
  margin-top: 16px;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  padding: 0.55vw 1.9vw 1.66vw 1.9vw;
  justify-content: space-between;
  border: ${(props) => props.theme.boxBorder.secondary};
  width: calc(100% - 4.9vw);
  border-radius: 2.22vw;
  background-color: ${(props) => props.theme.colors.white};
  text-align: center;
`;

const Note = styled.div`
  text-align: end;
  letter-spacing: 1.44px;
  font-size: 2.5vw;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${(props) => props.theme.colors.error2};
`;

const BlockContainer = styled.div`
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: center;
  display: flex;
  flex-direction: column;
  max-width: 25%;
`;

const Title = styled.div`
  font-size: ${(props) => props.fontSize || '2.5vw'};
  color: ${(props) => props.theme.colors.textSecondary};
`;

const ValueContainer = styled.div`
  margin: 8px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
const Value = styled.div`
  font-size: ${(props) => props.fontSize || '5vw'};
  color: ${(props) => props.color || props.theme.colors.textPrimary};
  letter-spacing: ${(props) => props.letterSpacing || '8.53px'};
  @media screen and (min-width: 1400px) {
    font-size: ${(props) => props.fontSize || '5vw'};
  }
`;

function InfoBlock({ title, content }) {
  return (
    <BlockContainer>
      <Title>{title}</Title>
      <ValueContainer>
        <Value
          color={content.color}
          fontSize={content.fontSize}
          letterSpacing={content.letterSpacing}
        >
          {content.value}
          {content.value2 && (
            <span>
              <br /> {`${content.value2}`}
            </span>
          )}
        </Value>
      </ValueContainer>
    </BlockContainer>
  );
}

function ProgressCard({ appointment }) {
  const { doctorName, subject, room, currentSeq, waitingCount, updatedAt } =
    appointment;
  const hideWaitingInfo = getURLSearchParamByKey('hideWaitingInfo');
  const isOpen = currentSeq && currentSeq !== '尚未開診';
  const isMainpi = getOption() === 'mainpi';
  const isSeqOutdated = isAfterMin(updatedAt, 30);
  const keyValue = {
    [t('currentNumber')]: {
      value: isOpen ? currentSeq : '00',
      color: theme.colors.primary,
      fontSize: '6.9vw',
      letterSpacing: '6.67px',
    },
    [t('doctorTitle')]: { value: doctorName },
    [t('department')]: {
      value: subject,
      value2: room,
      fontSize: room ? '3.3vw' : undefined,
    },
    [t('currentWaitingCount')]: {
      value: isOpen && !isMainpi ? `${waitingCount}${t('people')}` : '-',
    },
    [t('updateTime')]: {
      value: isOpen && updatedAt != null ? toTime(updatedAt) : '-',
      letterSpacing: '2.56px',
    },
  };
  if (hideWaitingInfo) {
    delete keyValue[t('currentWaitingCount')];
  }
  return (
    <CardContainer>
      <Container>
        {Object.keys(keyValue).map((k) => (
          <InfoBlock key={k} title={k} content={keyValue[k]}></InfoBlock>
        ))}
      </Container>
      {isSeqOutdated && <Note> {t('updateDelay')}</Note>}
    </CardContainer>
  );
}

ProgressCard.defaultProps = {
  appointment: [],
};

ProgressCard.propTypes = {
  appointment: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    relation: PropTypes.string,
    doctorName: PropTypes.string,
    subject: PropTypes.string,
    room: PropTypes.string,
    currentNumber: PropTypes.number,
    number: PropTypes.number,
    waiting: PropTypes.number,
    cancelable: PropTypes.bool,
  }),
};

ProgressCard.defaultProps = {
  availables: [],
};
export default ProgressCard;
