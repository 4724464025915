import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ProgressCard from './ProgressCardV2';
import format from 'date-fns/format';
import { t } from 'src/i18n/config';

const Container = styled.div`
  margin: 0 2.7vw 0 2.7vw;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2.7vw 8px 3.8vw 8px;
  width: 100%;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  font-size: 4.4vw;
  letter-spacing: 2.56px;
`;

const Open = styled.div`
  color: ${(props) =>
    props.isOpen ? props.theme.colors.success2 : props.theme.colors.default};
`;
const TimeBlock = styled.div`
  display: flex;
  align-items: center;
  font-size: 2.5vw;
  color: ${(props) => props.theme.colors.default};
`;
const Period = styled.div`
  margin: 8px 0;
`;
const Time = styled.div`
  margin-left: 8px;
  font-size: 4.4vw;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.textPrimary};
`;

const defaultEmptyAppointments = [
  {
    id: '1',
    doctorName: '-',
    subject: '-',
  },
  {
    id: '2',
    doctorName: '-',
    subject: '-',
  },
];

function ProgressPage({ appointments, loading, onRefresh }) {
  const [currentTime, setCurrentTime] = useState(format(new Date(), 'HH:mm'));
  const isOpen =
    appointments.length > 0 &&
    appointments[0].currentSeq &&
    appointments[0].currentSeq !== '尚未開診';
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(format(new Date(), 'HH:mm'));
    }, 1000);
    const refreshInterval = setInterval(() => {
      onRefresh();
    }, 60000);
    return () => {
      clearInterval(interval);
      clearInterval(refreshInterval);
    };
  }, [onRefresh]);
  return (
    <Container>
      <Header>
        <Open isOpen={isOpen}>
          {isOpen ? t('inConsultation') : t('noAppointment')}
        </Open>
        <TimeBlock>
          {t('currentDateTime')}
          <Time>
            {isOpen && (
              <Period> {isOpen && appointments[0].periodString}</Period>
            )}
            {currentTime}
          </Time>
        </TimeBlock>
      </Header>
      {(appointments.length === 0 || loading
        ? defaultEmptyAppointments
        : appointments
      ).map((appointment) => {
        return (
          <ProgressCard
            key={appointment.id}
            appointment={appointment}
          ></ProgressCard>
        );
      })}
    </Container>
  );
}

ProgressPage.propTypes = {
  onRefresh: PropTypes.func,
};
ProgressPage.defaultProps = {
  appointments: [],
};

export default ProgressPage;
