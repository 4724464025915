import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { debounce } from 'lodash-es';
import Layout from 'components/layout';
import AppointmentsProgressPublicPage from 'components/appointments/progress/ProgressPublicPage';
import { fetchPublicProgress } from 'services/appointment.service';
import useMainpi from 'src/customHooks/mainpi';
import { getDataSource } from 'src/utils';
import { t } from 'src/i18n/config';

async function fetchData() {
  const as = await fetchPublicProgress();
  return {
    as,
  };
}

const AppointmentsPage = () => {
  const [appointments, setAppointments] = useState([]);
  useEffect(() => {
    fetchData().then(({ as }) => {
      setAppointments(as);
    });
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRefresh = useCallback(
    debounce(() => {
      fetchData().then(({ as }) => {
        setAppointments(as);
      });
    }, 300),
    []
  );

  const { mainpiCodes, loading: mainpiLoading } = useMainpi(
    appointments.filter((a) => a.waitingCode !== '').map((a) => a.waitingCode)
  );

  appointments.forEach((a) => {
    const call = mainpiCodes[a.waitingCode];
    // sometimes mainpi is still responded but visionweb is stalled, in this case we follow visionweb
    if (getDataSource() === 'vs' && a.currentSeq === '尚未開診') {
      return;
    }
    if (call) {
      a.currentSeq = call.num;
      a.updatedAt = call.lastUpdate;
    }
  });
  return (
    <Layout>
      <Helmet>
        <title>{t('departmentProgress')}</title>
      </Helmet>
      <AppointmentsProgressPublicPage
        appointments={appointments}
        loading={appointments == null || mainpiLoading}
        onRefresh={handleRefresh}
      ></AppointmentsProgressPublicPage>
    </Layout>
  );
};

export default AppointmentsPage;
